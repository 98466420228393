import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import dayjs from 'dayjs';

import { Redirect } from 'react-router';
import { Module, decimalFormatter, fileTypes, theme } from '~/utils';
import { Col, FileGroup, Row, Tag } from '~/UI';
import { FileTypes, ModuleFile } from '~/types/file';
import useModulePermissions, {
  UseModuleFilesPermissions,
} from '~/hooks/Permissions/useModulePermissions';
import { useLazyGetModuleFileQuery } from '~/store/api';
import { ROUTER_PATHS } from '~/router/Paths';

const Block = styled.div`
  background-color: ${theme.colors.white};
  height: 100%;
  padding: 1rem 30px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 0.2rem;
  padding: 0.6rem 0;
`;

const StyledTitle = styled(Typography.Paragraph)`
  font-size: 1.5rem;
  font-weight: 700;
`;

const StyledLabel = styled(Typography.Paragraph)`
  font-weight: 700;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const displayDataOrDashes = (
  data: number | string,
  prefix = '',
  formattedData?: string | number | bigint
) => {
  if (data) return `${formattedData || data} ${prefix}`;
  if (data === 0) return `${data} ${prefix}`;

  return '-';
};

const getTemperatureCoefficient = (value: string) => {
  const coefficientPercentageByCelcius = Number(value) * 100;
  return displayDataOrDashes(
    coefficientPercentageByCelcius,
    '%/°C',
    parseFloat(coefficientPercentageByCelcius.toString()).toFixed(3)
  );
};

export type propsModuleDetailsListProps = {
  moduleDetails: Module;
};

export interface ModuleDetailsType extends Module {
  uuid: string;
  anza_pan_file_expected_availability_date: string;
  third_party_pan_file_expected_availability_date: string;
  manufacturer_pan_file_expected_availability_date: string;
}

const ModuleDetailsList = ({ moduleDetails }: propsModuleDetailsListProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Row>
        <Col span={24}>
          <StyledTitle>Warranty</StyledTitle>
        </Col>
        <Col span={12}>
          <StyledLabel>Year 1 Degradation</StyledLabel>
        </Col>
        <Col span={12}>
          {displayDataOrDashes(
            moduleDetails.warrantied_year_one_degradation,
            '%',
            (
              Number(moduleDetails.warrantied_year_one_degradation) * 100
            ).toFixed(2)
          )}
        </Col>
        <Col span={12}>
          <StyledLabel>Annual Degradation</StyledLabel>
        </Col>
        <Col span={12}>
          {displayDataOrDashes(
            moduleDetails.yearly_degradation,
            '%',
            (Number(moduleDetails.yearly_degradation) * 100).toFixed(2)
          )}
        </Col>
        {moduleDetails.performance_warranty !== null && (
          <>
            <Col span={12}>
              <StyledLabel>Performance Warranty</StyledLabel>
            </Col>
            <Col span={12}>{moduleDetails.performance_warranty} Years</Col>
          </>
        )}
      </Row>
      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <StyledTitle>Mechanical</StyledTitle>
        </Col>
        <Col span={12}>
          <StyledLabel>Long Edge (length)</StyledLabel>
        </Col>
        <Col span={12}>
          {displayDataOrDashes(
            moduleDetails.long_edge,
            'mm',
            decimalFormatter(moduleDetails.long_edge)
          )}
        </Col>
        <Col span={12}>
          <StyledLabel>Short Edge (width)</StyledLabel>
        </Col>
        <Col span={12}>
          {displayDataOrDashes(
            moduleDetails.short_edge,
            'mm',
            decimalFormatter(moduleDetails.short_edge)
          )}
        </Col>
        <Col span={12}>
          <StyledLabel>Frame Thickness</StyledLabel>
        </Col>
        <Col span={12}>
          {displayDataOrDashes(
            moduleDetails.frame_width,
            'mm',
            decimalFormatter(moduleDetails.frame_width)
          )}
        </Col>
        <Col span={12}>
          <StyledLabel>Weight</StyledLabel>
        </Col>
        <Col span={12}>
          {displayDataOrDashes(
            moduleDetails.weight_kg,
            'kg',
            parseFloat(moduleDetails.weight_kg).toFixed(1)
          )}
        </Col>
        <Col span={12}>
          <StyledLabel>Lead Length</StyledLabel>
        </Col>
        <Col span={12}>
          {moduleDetails.lead_length
            ? moduleDetails.lead_length.map((lead: string) => (
                <Tag
                  color="primary"
                  key={lead}
                  text={lead}
                  style={{ fontWeight: 'bold' }}
                />
              ))
            : '-'}
        </Col>
        <Col span={12}>
          <StyledLabel>Connector Type</StyledLabel>
        </Col>
        <Col span={12}>
          {moduleDetails.connector_type
            ? moduleDetails.connector_type.map((connector: string) => (
                <Tag
                  color="primary"
                  key={connector}
                  text={connector}
                  style={{ fontWeight: 'bold' }}
                />
              ))
            : '-'}
        </Col>
      </Row>

      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <StyledTitle>Electrical</StyledTitle>
        </Col>
        <Col span={12}>
          <StyledLabel>Voc</StyledLabel>
        </Col>
        <Col span={12}>
          {displayDataOrDashes(
            moduleDetails.voc,
            'V',
            parseFloat(moduleDetails.voc).toFixed(2)
          )}
        </Col>
        <Col span={12}>
          <StyledLabel>Isc</StyledLabel>
        </Col>
        <Col span={12}>{displayDataOrDashes(moduleDetails.isc_amps, 'A')}</Col>
        <Col span={12}>
          <StyledLabel>VMP</StyledLabel>
        </Col>
        <Col span={12}>
          {displayDataOrDashes(moduleDetails.vmp_voltage, 'V')}
        </Col>
        <Col span={12}>
          <StyledLabel>IMP</StyledLabel>
        </Col>
        <Col span={12}>{displayDataOrDashes(moduleDetails.imp_amps, 'A')}</Col>
        <Col span={12}>
          <StyledLabel>Temperature Coefficient of Isc</StyledLabel>
        </Col>
        <Col span={12}>
          {getTemperatureCoefficient(moduleDetails.temperature_coeffient_isc)}
        </Col>
        <Col span={12}>
          <StyledLabel>Temperature Coefficient of Voltage</StyledLabel>
        </Col>
        <Col span={12}>
          {getTemperatureCoefficient(
            moduleDetails.temperature_coeffient_voltage
          )}
        </Col>
        <Col span={12}>
          <StyledLabel>Temperature Coefficient of Pmax</StyledLabel>
        </Col>
        <Col span={12}>
          {getTemperatureCoefficient(moduleDetails.temperature_coeffient_pmax)}
        </Col>
      </Row>

      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <StyledTitle>Approvals and Certificates</StyledTitle>
        </Col>
        <Col span={12}>
          <StyledLabel>UL Listed (61730)</StyledLabel>
        </Col>
        <Col span={12}>{moduleDetails.ul_listed ? 'Yes' : 'No'}</Col>
        <Col span={12}>
          <StyledLabel>IEC Listed (61215)</StyledLabel>
        </Col>
        <Col span={12}>{moduleDetails.iec_listed ? 'Yes' : 'No'}</Col>
        <Col span={12}>
          <StyledLabel>CEC Listed</StyledLabel>
        </Col>
        <Col span={12}>{moduleDetails.cec_listed ? 'Yes' : 'No'}</Col>
      </Row>
    </div>
  );
};

const ModuleFileList: React.FC<{
  [key: string]: any;
  moduleUuid: string;
  filesPermissions: UseModuleFilesPermissions;
}> = ({
  moduleDatasheet,
  anzaPanFile,
  anzaPanFileSupportingFiles,
  anzaPanFileSupportingFilesZip,
  manufacturerPanFile,
  manufacturerPanFileSupportingFiles,
  manufacturerPanFileSupportingFilesZip,
  thirdPartyPanFile,
  thirdPartyPanFileSupportingFiles,
  thirdPartyPanFileSupportingFilesZip,
  moduleUuid,
  anzaFileExpectedDate,
  thirdPartyFileExpectedDate,
  manufacturerFileExpectedDate,
  filesPermissions,
}) => {
  const [downloadFile, { data: filedata }] = useLazyGetModuleFileQuery();
  useEffect(() => {
    if (filedata) {
      window.open(filedata.data.presignedUrl);
    }
  }, [filedata]);

  const handleFileDownload = async (fileUuid: string, fileType?: FileTypes) => {
    downloadFile({ moduleUuid, fileUuid, fileType });
  };

  const [anzaFiles, thirdPartyFiles, manufacturerFiles] = [
    anzaPanFileSupportingFiles.map((el: ModuleFile) => ({
      ...el,
      onDownload: () =>
        handleFileDownload(el.uuid, 'anzaPanFileSupportingFile'),
      uploadDate: el.updated_at,
      sizeInMB: el.file_size_kb / 1024,
    })),
    thirdPartyPanFileSupportingFiles.map((el: ModuleFile) => ({
      ...el,
      onDownload: () =>
        handleFileDownload(el.uuid, 'thirdPartyPanFileSupportingFile'),
      uploadDate: el.updated_at,
      sizeInMB: el.file_size_kb / 1024,
    })),
    manufacturerPanFileSupportingFiles.map((el: ModuleFile) => ({
      ...el,
      onDownload: () =>
        handleFileDownload(el.uuid, 'manufacturerPanFileSupportingFile'),
      uploadDate: el.updated_at,
      sizeInMB: el.file_size_kb / 1024,
    })),
  ];

  return (
    <StyledContainer>
      <FileGroup
        name="Datasheet"
        fileId={moduleDatasheet?.uuid}
        description={moduleDatasheet?.notes}
        sizeInMB={moduleDatasheet?.file_size_kb}
        uploadDate={moduleDatasheet?.updated_at}
        onDownload={() =>
          handleFileDownload(
            moduleDatasheet?.uuid,
            fileTypes.MODULE_DATASHEET.type as FileTypes
          )
        }
        isDownloadDisabled={
          !Object.keys(moduleDatasheet).length ||
          !filesPermissions.moduleDatasheet.enableDownload
        }
      />

      <FileGroup
        name="Anza PAN File"
        fileId={anzaPanFile?.uuid}
        description={anzaPanFile?.notes}
        sizeInMB={anzaPanFile?.file_size_kb}
        uploadDate={anzaPanFile?.updated_at}
        onDownload={() => handleFileDownload(anzaPanFile?.uuid, 'anzaPanFile')}
        supportingDocuments={anzaPanFile?.uuid ? anzaFiles : []}
        onDownloadAll={() =>
          handleFileDownload(anzaPanFileSupportingFilesZip?.uuid)
        }
        isDownloadDisabled={
          !anzaPanFile.uuid || !filesPermissions.anzaPanFile.enableDownload
        }
        expectedDate={
          anzaFileExpectedDate && !anzaPanFile?.uuid
            ? dayjs(anzaFileExpectedDate).format('MMM YYYY')
            : ''
        }
      />

      <FileGroup
        name="Third Party PAN File"
        fileId={thirdPartyPanFile?.uuid}
        description={thirdPartyPanFile?.notes}
        sizeInMB={thirdPartyPanFile?.file_size_kb}
        uploadDate={thirdPartyPanFile?.updated_at}
        onDownload={() =>
          handleFileDownload(thirdPartyPanFile?.uuid, 'thirdPartyPanFile')
        }
        supportingDocuments={thirdPartyPanFile?.uuid ? thirdPartyFiles : []}
        onDownloadAll={() =>
          handleFileDownload(
            thirdPartyPanFileSupportingFilesZip?.uuid,
            'thirdPartyPanSupportingFilesZip'
          )
        }
        isDownloadDisabled={
          !thirdPartyPanFile?.uuid ||
          !filesPermissions.thirdPartyPanFile.enableDownload
        }
        expectedDate={
          thirdPartyFileExpectedDate && !thirdPartyPanFile?.uuid
            ? dayjs(thirdPartyFileExpectedDate).format('MMM YYYY')
            : ''
        }
      />

      <FileGroup
        name="Manufacturer PAN File"
        fileId={manufacturerPanFile?.uuid}
        description={manufacturerPanFile?.notes}
        sizeInMB={manufacturerPanFile?.file_size_kb}
        uploadDate={manufacturerPanFile?.updated_at}
        onDownload={() =>
          handleFileDownload(manufacturerPanFile?.uuid, 'manufacturerPanFile')
        }
        supportingDocuments={manufacturerPanFile?.uuid ? manufacturerFiles : []}
        onDownloadAll={() =>
          handleFileDownload(
            manufacturerPanFileSupportingFilesZip?.uuid,
            'manufacturerPanSupportingFilesZip'
          )
        }
        isDownloadDisabled={
          !manufacturerPanFile?.uuid ||
          !filesPermissions.manufacturerPanFile.enableDownload
        }
        expectedDate={
          manufacturerFileExpectedDate && !manufacturerPanFile.uuid
            ? dayjs(manufacturerFileExpectedDate).format('MMM YYYY')
            : ''
        }
      />
    </StyledContainer>
  );
};

interface ModuleDetailsProps {
  moduleDetails: ModuleDetailsType;
  fileGroups: {
    moduleDatasheet: Record<string, unknown>;
    anzaPanFile: Record<string, unknown>;
    thirdPartyPanFile: Record<string, unknown>;
    manufacturerPanFile: Record<string, unknown>;
    anzaPanFileSupportingFilesZip: Record<string, unknown>;
    thirdPartyPanFileSupportingFilesZip: Record<string, unknown>;
    manufacturerPanFileSupportingFilesZip: Record<string, unknown>;
    anzaPanFileSupportingFiles: [];
    thirdPartyPanFileSupportingFiles: [];
    manufacturerPanFileSupportingFiles: [];
  };
  filesPermissions: UseModuleFilesPermissions;
}

const StyledBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
`;

const ModuleDetails: React.FC<ModuleDetailsProps> = ({
  moduleDetails,
  fileGroups,
  filesPermissions,
}) => {
  const modulePermissions = useModulePermissions(moduleDetails.permissions);

  if (!modulePermissions.isModuleDetailsPageEnabled) {
    return <Redirect to={ROUTER_PATHS.PAGE_NOT_FOUND()} />;
  }
  return (
    <>
      <StyledRow>
        <Col span={12}>
          <StyledBlock>
            <ModuleDetailsList moduleDetails={moduleDetails} />
          </StyledBlock>
        </Col>
        <Col span={12}>
          <Block
            style={{
              borderTopRightRadius: '0.5rem',
              borderBottomRightRadius: '0.5rem',
            }}
          >
            <StyledTitle>Files</StyledTitle>
            <ModuleFileList
              filesPermissions={filesPermissions}
              moduleUuid={moduleDetails.uuid}
              moduleDatasheet={fileGroups.moduleDatasheet}
              manufacturerPanFile={fileGroups.manufacturerPanFile}
              anzaPanFile={fileGroups.anzaPanFile}
              thirdPartyPanFile={fileGroups.thirdPartyPanFile}
              anzaPanFileSupportingFiles={fileGroups.anzaPanFileSupportingFiles}
              thirdPartyPanFileSupportingFiles={
                fileGroups.thirdPartyPanFileSupportingFiles
              }
              manufacturerPanFileSupportingFiles={
                fileGroups.manufacturerPanFileSupportingFiles
              }
              anzaPanFileSupportingFilesZip={
                fileGroups.anzaPanFileSupportingFilesZip
              }
              thirdPartyPanFileSupportingFilesZip={
                fileGroups.thirdPartyPanFileSupportingFilesZip
              }
              manufacturerPanFileSupportingFilesZip={
                fileGroups.manufacturerPanFileSupportingFilesZip
              }
              anzaFileExpectedDate={
                moduleDetails.anza_pan_file_expected_availability_date
              }
              thirdPartyFileExpectedDate={
                moduleDetails.third_party_pan_file_expected_availability_date
              }
              manufacturerFileExpectedDate={
                moduleDetails.manufacturer_pan_file_expected_availability_date
              }
            />
          </Block>
        </Col>
      </StyledRow>
    </>
  );
};

export default ModuleDetails;
