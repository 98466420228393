import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { useFlags } from '~/utils/launchdarkly';

import Header from '~/UI/Header/Header';
import useAuth from '~/hooks/useAuth';
import { MAILTO, isUserAdmin, isUserVendor, theme } from '~/utils';
import {
  ContactUsButton,
  ContactUsIcon,
  Mailto,
  PrimaryItem,
  SecondaryTab,
  SecondaryTabLink,
  UserAvatar,
} from '~/UI/Header/styles';
import { generateTestId, TEST_DATA_COMPONENTS } from '~/utils/dataTestProps';
import { IconArrowBack, IconLogOut, IconSettings } from '~/UI/Icons';
import { ROUTER_PATHS } from '~/router/Paths';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { DropdownAnt as Dropdown, ButtonAnt as Button } from '~/UI';
import { filterAdminRoutesByStorageModulesFeatureFlag } from '~/utils/routes';
import { BackButtonProps } from './BackHandler';
import { DEFAULT_ADMIN_ROUTE } from '~/router/routes';

const DropdownContainer = styled.div`
  background-color: ${theme.colors.background};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid ${theme.colors.gray100};
  border-radius: 8px;
  margin-top: 18px;
`;

const SignOutButton = styled(Button)`
  width: 300px;
  height: 52px;
  display: flex;
  gap: 8px;
`;

const UserDataContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const UserFullName = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

const UserJob = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.gray700};
`;

const StyledSettingsLink = styled(Link)`
  margin-left: auto;
`;

const SettingsButton = styled(Button)`
  padding: 10px;
  border: 1px solid ${theme.colors.gray200};
`;

const ClickableUserAvatar = styled(UserAvatar)`
  cursor: pointer;
`;

const AdminLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  font-weight: 600;
  font-size: 14px;
  color: ${theme.colors.white};
  background-color: ${theme.colors.primaryDark500};
  border-radius: 6px;
  width: 67px;
  height: 34px;
`;

const PrimaryNavLink = styled(NavLink)`
  &.active {
    button {
      background-color: rgba(0, 0, 0, 0.24);
    }
  }
`;

type NavBarProps = {
  backHandlerData?: BackButtonProps;
  hideMainItems?: boolean;
  hideSecondaryItems?: boolean;
  hideRightMenuItems?: boolean;
  pathname?: string;
  style?: React.CSSProperties;
};

const NavBar = ({
  backHandlerData,
  hideMainItems,
  hideSecondaryItems,
  hideRightMenuItems,
  pathname: customPathname,
  style,
}: NavBarProps) => {
  const { user, signOutUser } = useAuth();
  const flags = useFlags();
  const { pathname } = customPathname ?? useLocation();
  const [showEmail, setShowEmail] = useState<boolean>(false);

  const isAdmin = isUserAdmin(user);
  const isVendor = isUserVendor(user);

  const isLocationInAdminDashboard = /admin\/dashboard/g.test(pathname);
  const isLocationProjectDetailsPage =
    /projects\/solar/g.test(pathname) || /projects\/storage/g.test(pathname);
  const isLocationModuleDetailsPage = /module\//g.test(pathname);
  const isLocationInAdminPage = /admin/g.test(pathname);

  const isAdminToolsActive =
    isAdmin &&
    (isLocationInAdminPage ||
      isLocationProjectDetailsPage ||
      isLocationModuleDetailsPage) &&
    !isLocationInAdminDashboard;

  const { go } = useHistory();

  const mainMenuItems = (
    <>
      {backHandlerData?.visible && (
        <>
          {backHandlerData.goBackHistory ? (
            <PrimaryItem
              onClick={() => {
                go(-1);
              }}
            >
              <IconArrowBack size={20} />
              {backHandlerData.text}
            </PrimaryItem>
          ) : (
            <Link
              to={{
                pathname: backHandlerData.to,
                state: backHandlerData.params
                  ? { fromLocation: backHandlerData.params.fromLocation }
                  : null,
              }}
            >
              <PrimaryItem>
                <IconArrowBack size={20} />
                {backHandlerData.text}
              </PrimaryItem>
            </Link>
          )}
        </>
      )}

      {isAdmin && (
        <>
          <PrimaryNavLink to={ROUTER_PATHS.ADMIN_DASHBOARD()}>
            <PrimaryItem>Dashboard</PrimaryItem>
          </PrimaryNavLink>
          <Link to={DEFAULT_ADMIN_ROUTE}>
            <PrimaryItem active={isAdminToolsActive}>Admin Tools</PrimaryItem>
          </Link>
        </>
      )}

      {isVendor && (
        <>
          <PrimaryNavLink to={ROUTER_PATHS.vendors.PIPELINE()}>
            <PrimaryItem>Dashboard</PrimaryItem>
          </PrimaryNavLink>
          <PrimaryNavLink to={ROUTER_PATHS.vendors.OUR_MODULES()}>
            <PrimaryItem>All Modules</PrimaryItem>
          </PrimaryNavLink>
          {flags.marketIntelVisualization && (
            <PrimaryNavLink to={ROUTER_PATHS.vendors.MARKET_INTELLIGENCE()}>
              <PrimaryItem>Market Intelligence</PrimaryItem>
            </PrimaryNavLink>
          )}
        </>
      )}
    </>
  );

  const { first_name, last_name } = user;

  let avatarContent = `${first_name ? first_name[0] : ''}${
    last_name ? last_name[0] : ''
  }`.toUpperCase();

  if (first_name && first_name.toUpperCase() === last_name?.toUpperCase()) {
    avatarContent = first_name[0].toUpperCase();
  }

  const rightMenuItems = (
    <>
      <ContactUsButton
        data-testid={generateTestId({
          component: `${TEST_DATA_COMPONENTS.APPLICATION_HEADER}-${TEST_DATA_COMPONENTS.BUTTON}`,
          identifier: 'contact-us',
        })}
        onClick={() => setShowEmail((prev) => !prev)}
      >
        <ContactUsIcon size={20} />
        Contact Us
      </ContactUsButton>
      {showEmail && (
        <Mailto href={`mailto:${MAILTO}?subject=Anza - Help`} target="_blank">
          {MAILTO}
        </Mailto>
      )}
      <div id="main-menu-user-profile">
        <Dropdown
          placement="bottomRight"
          overlayClassName="main-menu-item"
          getPopupContainer={() =>
            document.getElementById('main-menu-user-profile')!
          }
          dropdownRender={() => {
            return (
              <DropdownContainer>
                <UserDataContainer>
                  <UserAvatar
                    fontSize={20}
                    color={theme.colors.primaryLight300}
                    size={48}
                  >
                    {avatarContent}
                  </UserAvatar>
                  <UserInfo>
                    <UserFullName>
                      {user.first_name} {user.last_name}
                    </UserFullName>
                    <UserJob>{user.job_title}</UserJob>
                  </UserInfo>
                  <StyledSettingsLink to={ROUTER_PATHS.SETTINGS()}>
                    <SettingsButton>
                      <IconSettings color={theme.colors.gray600} size={24} />
                    </SettingsButton>
                  </StyledSettingsLink>
                </UserDataContainer>
                <SignOutButton onClick={signOutUser}>
                  <IconLogOut size={24} /> Sign Out
                </SignOutButton>
              </DropdownContainer>
            );
          }}
          trigger={['click']}
        >
          <ClickableUserAvatar
            fontSize={14}
            color={theme.colors.primaryDark500}
            size={32}
            data-testid={generateTestId({
              component: `${TEST_DATA_COMPONENTS.APPLICATION_HEADER}-${TEST_DATA_COMPONENTS.AVATAR}`,
              identifier: 'user',
            })}
          >
            {avatarContent}
          </ClickableUserAvatar>
        </Dropdown>
      </div>
      {isAdmin && <AdminLabel>Admin</AdminLabel>}
    </>
  );

  const filteredAdminItems = filterAdminRoutesByStorageModulesFeatureFlag(
    flags.storageModule,
    ADMIN_ROUTES
  );

  const filteredAdminNavItems = filteredAdminItems.filter(
    ({ hideFromNavbar }) => !hideFromNavbar
  );

  const adminItems = filteredAdminNavItems.map(({ path, text }) => {
    const active = text === 'Projects' && isLocationProjectDetailsPage;
    return (
      <SecondaryTabLink
        active={active}
        key={`menu-item-${text}`}
        to={path.default}
      >
        <SecondaryTab>{text}</SecondaryTab>
      </SecondaryTabLink>
    );
  });

  return (
    <Header
      style={style}
      mainMenuItems={!hideMainItems ? mainMenuItems : null}
      rightMenuItems={!hideRightMenuItems ? rightMenuItems : null}
      secondaryMenuItems={isAdmin && !hideSecondaryItems ? adminItems : null}
      showSecondaryMenu={isAdminToolsActive}
    />
  );
};

export default NavBar;
