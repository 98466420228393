import * as R from 'ramda';
import { AdminRoutesConfig } from '~/router/AdminRoutes';

export const filterAdminRoutesByStorageModulesFeatureFlag = (
  STORAGE_MODULE: boolean,
  ADMIN_ROUTES: AdminRoutesConfig
) => {
  return STORAGE_MODULE
    ? R.values(R.omit(['ANZA_PROJECTS'], ADMIN_ROUTES))
    : R.values(R.omit(['PCS', 'DC_BLOCKS', 'ANZA_PROJECTS'], ADMIN_ROUTES));
};
