import React from 'react';

import MaintenancePage from 'routes/MaintenancePage';

import Login from 'routes/Login';
import AdminDashboard from 'routes/AdminDashboard';
import MarketingCustomers from 'routes/MarketingCustomers';
import MarketingVendors from 'routes/MarketingVendors';
import Settings from 'routes/Settings';
import Schedule from 'routes/Schedule';
import ErrorPage from 'routes/ErrorPage';
import Auth from 'routes/Auth';
import PageNotFound from 'routes/PageNotFound';
import ModuleDatabaseView from 'routes/ModuleDatabaseView';
import AnzaOurModules from 'routes/AnzaOurModulesList';
import VendorPricing from 'routes/VendorProjectPricing';
import PricingHistory from 'routes/PricingHistory';
import Admin from 'routes/Admin';
import Dashboard from '~/components/DashboardView';
import { BackButtonProps } from '~/components/BackHandler';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { UserType } from '~/types/users';
import {
  ROLES,
  isUserAdmin,
  isUserCustomer,
  navigateBackToPDP,
  MDPLocation,
} from '~/utils';
import { ROUTER_PATHS } from './Paths';
import { AnzaLayout } from '../components/Anza/AnzaLayout';
import { SpinnerMask } from '../UI/Spinner';
import MarketIntelligenceView from '~/components/MarketIntelligenceView';

export type BackHandlerFunction = (
  user: UserType,
  location: MDPLocation
) => BackButtonProps;

export interface ModelRoute {
  id: string;
  title?: string;
  path: (args?: string) => string;
  exact?: boolean;
  authenticated: boolean;
  component: React.FC<any>;
  roles?: Array<(typeof ROLES)[keyof typeof ROLES]>;
  layout?: React.FC<any>;
  /**
   * Function used by the layout to render the "BackHandler" button on the navbar
   */
  navbarBackHandler?: BackHandlerFunction;
  /**
   * Function used by the layout to render the "BackHandler" button on the content of the page
   */
  contentBackHandler?: BackHandlerFunction;
  hideNavBarMainItems?: boolean;
  hideNavBarSecondaryItems?: boolean;
  hideNavBarRightMenuItems?: boolean;
  gtmId?: string;
}

export const ROUTES: { [key: string]: ModelRoute } = {
  LOGIN: {
    id: 'login',
    title: 'Login',
    path: ROUTER_PATHS.LOGIN,
    exact: true,
    component: Login,
    authenticated: false,
  },
  ADMIN_DASHBOARD: {
    id: 'adminDashboard',
    title: 'Admin Dashboard',
    path: ROUTER_PATHS.ADMIN_DASHBOARD,
    exact: true,
    component: AdminDashboard,
    authenticated: true,
    roles: [ROLES.admin],
  },
  ADMIN: {
    id: 'admin',
    path: ROUTER_PATHS.ADMIN,
    exact: false,
    component: Admin,
    authenticated: true,
    roles: [ROLES.admin],
  },
  AUTH: {
    id: 'auth',
    title: 'Auth',
    path: ROUTER_PATHS.AUTH,
    exact: false,
    component: Auth,
    authenticated: false,
  },
  MARKETING_CUSTOMERS: {
    id: 'marketing-customers-page',
    title: 'Marketing Customers Page',
    path: ROUTER_PATHS.MARKETING_CUSTOMERS,
    exact: true,
    component: MarketingCustomers,
    authenticated: false,
  },
  MARKETING_VENDORS: {
    id: 'marketing-vendors-page',
    title: 'Marketing Vendors Page',
    path: ROUTER_PATHS.MARKETING_VENDORS,
    exact: true,
    component: MarketingVendors,
    authenticated: false,
  },
  SETTINGS: {
    id: 'settings',
    title: 'Settings',
    path: ROUTER_PATHS.SETTINGS,
    exact: true,
    component: Settings,
    layout: AnzaLayout,
    authenticated: true,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.customer, ROLES.vendor],
    navbarBackHandler: (user) => ({
      text: 'All Projects',
      to: ROUTER_PATHS.ADMIN(),
      visible: isUserCustomer(user) && !isUserAdmin(user),
    }),
  },
  SCHEDULE: {
    id: 'Schedule',
    title: 'Schedule a Call',
    path: ROUTER_PATHS.SCHEDULING,
    component: Schedule,
    exact: true,
    authenticated: true,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.customer, ROLES.vendor],
    layout: AnzaLayout,
  },
  ERROR: {
    id: 'error',
    title: 'Error',
    path: ROUTER_PATHS.ERROR,
    exact: true,
    component: ErrorPage as unknown as React.FC,
    authenticated: false,
  },
  PAGE_NOT_FOUND: {
    id: '404',
    title: '404',
    path: ROUTER_PATHS.PAGE_NOT_FOUND,
    exact: false,
    component: PageNotFound,
    authenticated: false,
  },
  MODULE_DETAILS: {
    id: 'Module',
    title: 'Module',
    gtmId: 'module',
    path: ROUTER_PATHS.modules.MODULE_DETAILS,
    component: ModuleDatabaseView,
    exact: false,
    authenticated: true,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.customer, ROLES.vendor],
    layout: AnzaLayout,
    navbarBackHandler: (user, location) => {
      // "fromProject" is set when clicking in a ModuleCard
      const fromProject = !!location?.state?.fromProject;
      const { to, params } = navigateBackToPDP(location, ROUTER_PATHS.ADMIN());
      const text = fromProject ? 'Project' : 'All Projects';
      return {
        text,
        to,
        visible: isUserCustomer(user) && !isUserAdmin(user),
        params,
        goBackHistory: fromProject,
      };
    },
    contentBackHandler: (user, location) => {
      const fromProject = !!location?.state?.fromProject;
      const defaultRoute = ADMIN_ROUTES.MODULES.path.default;
      const { to, params } = navigateBackToPDP(location, defaultRoute);
      const text = fromProject ? 'Project' : 'All Modules';
      return {
        text,
        to,
        visible: isUserAdmin(user),
        params,
        goBackHistory: fromProject || !to,
      };
    },
  },
  // TODO: The following 3 Anza routes are meant only for allowing users to access projects when using old anza routes. Remove later.
  ANZA_MODULES: {
    id: 'Modules',
    path: ROUTER_PATHS.projects.ANZA_MODULES,
    title: 'Modules',
    authenticated: true,
    component: () => <SpinnerMask />,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.customer],
    layout: AnzaLayout,
  },
  ANZA_STORAGE: {
    id: 'Storage',
    path: ROUTER_PATHS.projects.ANZA_STORAGE,
    title: 'Storage',
    authenticated: true,
    component: () => <SpinnerMask />,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.customer],
    layout: AnzaLayout,
  },
  VENDOR_PIPELINE: {
    id: 'Overview',
    title: 'Pipeline Overview',
    path: ROUTER_PATHS.vendors.PIPELINE,
    component: Dashboard,
    exact: true,
    authenticated: true,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.vendor],
    layout: AnzaLayout,
  },
  VENDOR_MARKET_INTELLIGENCE: {
    id: 'Market Intelligence',
    title: 'Market Intelligence',
    path: ROUTER_PATHS.vendors.MARKET_INTELLIGENCE,
    component: MarketIntelligenceView,
    exact: true,
    authenticated: true,
    roles: [ROLES.vendor],
    layout: AnzaLayout,
  },
  VENDOR_OUR_MODULES: {
    id: 'Manage Modules',
    title: 'Manage Modules',
    path: ROUTER_PATHS.vendors.OUR_MODULES,
    component: AnzaOurModules,
    exact: true,
    authenticated: true,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.vendor],
    layout: AnzaLayout,
  },
  VENDOR_MANAGE_MODULES: {
    id: 'Manage Modules',
    title: 'Manage Modules',
    path: ROUTER_PATHS.vendors.MODULES,
    component: AnzaOurModules,
    exact: true,
    authenticated: true,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.vendor],
    layout: AnzaLayout,
  },
  VENDOR_PROJECT_PRICING: {
    id: 'Project Pricing Analysis',
    title: 'Project Pricing Analysis',
    path: ROUTER_PATHS.vendors.PROJECT_PRICING,
    component: VendorPricing,
    exact: false,
    authenticated: true,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.vendor],
    layout: AnzaLayout,
    contentBackHandler: (user) => ({
      text: 'Dashboard',
      to: isUserAdmin(user)
        ? ROUTER_PATHS.ADMIN()
        : ROUTER_PATHS.vendors.PIPELINE(),
    }),
  },
  MAINTENANCE: {
    id: 'Maintenance',
    title: 'Maintenance',
    path: ROUTER_PATHS.MAINTENANCE,
    component: MaintenancePage,
    exact: false,
    authenticated: true,
    layout: AnzaLayout,
    roles: [ROLES.admin, ROLES.account_manager, ROLES.customer, ROLES.vendor],
    hideNavBarMainItems: true,
    hideNavBarSecondaryItems: true,
    hideNavBarRightMenuItems: true,
  },
  PRICING_HISTORY: {
    id: 'Pricing History',
    title: 'Pricing History',
    path: ROUTER_PATHS.dashboards.PRICING_HISTORY,
    component: PricingHistory,
    exact: false,
    authenticated: true,
    layout: AnzaLayout,
    roles: [ROLES.admin, ROLES.customer],
    navbarBackHandler: (user) => ({
      text: 'All Projects',
      to: ROUTER_PATHS.ADMIN(),
      visible: isUserCustomer(user) && !isUserAdmin(user),
    }),
    contentBackHandler: (user) => ({
      visible: isUserAdmin(user),
      text: 'All Projects',
      to: ADMIN_ROUTES.COMPANIES.path.default,
      goBackHistory: true,
    }),
  },
};

export const routes: Array<ModelRoute> = [
  ROUTES.LOGIN,
  ROUTES.AUTH,
  ROUTES.ADMIN_DASHBOARD,
  ROUTES.ADMIN,
  ROUTES.MARKETING_CUSTOMERS,
  ROUTES.MARKETING_VENDORS,
  ROUTES.SETTINGS,
  ROUTES.ERROR,
  ROUTES.PAGE_NOT_FOUND,
  ROUTES.MODULE_DETAILS,
  ROUTES.MAINTENANCE,
  ROUTES.PRICING_HISTORY,
];

export const projectRoutes: Array<ModelRoute> = [
  ROUTES.PROJECTS,
  ROUTES.SOLAR_PROJECT_DETAILS,
  ROUTES.SOLAR_PROJECT_DETAILS_DETAILED,
  ROUTES.STORAGE_PROJECT_DETAILS,
  ROUTES.STORAGE_PROJECT_DETAILS_DETAILED,
  ROUTES.ANZA_MODULES,
  ROUTES.ANZA_STORAGE,
];

export const vendorRoutes: Array<ModelRoute> = [
  ROUTES.VENDOR_PIPELINE,
  ROUTES.VENDOR_OUR_MODULES,
  ROUTES.VENDOR_MANAGE_MODULES,
  ROUTES.VENDOR_PROJECT_PRICING,
  ROUTES.VENDOR_MARKET_INTELLIGENCE,
];

export const DEFAULT_ADMIN_ROUTE = ADMIN_ROUTES.COMPANIES.path.default;
