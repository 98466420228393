import React, { memo, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Input as InputAnt, message } from 'antd';
import { Link } from 'react-router-dom';

import {
  ButtonAnt as Button,
  Form,
  Option,
  Switch,
  Radio,
  InputAnt as Input,
  InputNumber,
  Tooltip,
  FormGroup,
  FormItemLabel,
} from '~/UI';
import { RadioGroup } from '~/UI/Radio';
import { IconView, IconCopy } from '~/UI/Icons';
import { selectFilterOption, selectFilterSort } from '~/utils/comparer';
import {
  requiredFieldRule,
  ModuleOwnershipStatuses,
  notRequiredRules,
} from '~/utils';
import { inRangeValidator } from '~/utils/numberValidators';
import { PercentSettings } from '~/utils/formatters';
import { ROUTER_PATHS } from '~/router/Paths';
import { MODULE_TAGS } from '~/constants/modules';
import { SelectFormItem } from '~/UI/Select';
import { CompanyModulesDataWithYearsDisplayAndKey } from '~/components/SolarModules/ModulePricingFormGroup';
import { CompanyType } from '~/types/users';

const FIRST_SOLAR = 'First Solar';

const moduleOwnershipOptions = [
  {
    value: ModuleOwnershipStatuses.VENDOR,
    label: 'Vendor',
  },
  {
    value: ModuleOwnershipStatuses.INVENTORY,
    label: 'Inventory',
  },
  {
    value: ModuleOwnershipStatuses.PURCHASE_ORDER,
    label: 'Purchase Order',
  },
];
interface CompanyFormGroupProps {
  companies?: Array<CompanyType>;
  module?: CompanyModulesDataWithYearsDisplayAndKey;
  requiredRule: {
    required: boolean;
    message: string;
  };
  createMode: boolean;
  isAnzaActive: boolean;
  selectedCompany: number;
  lastUpdated?: Date;
}

const CompanyFormGroup: React.FC<CompanyFormGroupProps> = ({
  companies,
  module,
  requiredRule,
  isAnzaActive,
  selectedCompany,
  lastUpdated,
}) => {
  const firstSolarId = useMemo(() => {
    const firstSolar = companies?.find(
      (company) => company.name === FIRST_SOLAR
    );
    return firstSolar?.id;
  }, [companies]);

  const [companyId, setCompanyId] = useState<number>(selectedCompany);

  useEffect(() => {
    setCompanyId(selectedCompany);
  }, [companies]);

  return (
    <>
      <FormGroup key="General" style={{ maxWidth: '100%' }}>
        <Form.Item
          name="is_anza_activated"
          label={<FormItemLabel>Is Active</FormItemLabel>}
          labelCol={{ span: 24 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        {lastUpdated && (
          <div style={{ marginBottom: '20px' }}>
            Last Updated:{' '}
            {dayjs(lastUpdated).format('MM/D/YY')}
          </div>
        )}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(auto, 49%))',
            gridTemplateRows: 'auto',
            gap: '1rem',
          }}
        >
          <SelectFormItem
            name="company_id"
            label={<FormItemLabel>Company</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
            required
            selectProps={{
              placeholder: 'Select a company',
              showSearch: true,
              filterOption: selectFilterOption,
              filterSort: selectFilterSort,
              onChange: setCompanyId,
            }}
          >
            {companies!
              .sort((optionA: { name: string }, optionB: { name: string }) =>
                optionA.name
                  .toLowerCase()
                  .localeCompare(optionB.name.toLocaleLowerCase())
              )
              .map(({ id: companyId, name }) => (
                <Option key={companyId} value={companyId}>
                  {name}
                </Option>
              ))}
          </SelectFormItem>
          <Form.Item
            name="name"
            label={<FormItemLabel>Name</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              requiredRule,
              { whitespace: true, message: 'Name cannot be blank' },
            ]}
            required
          >
            <Input required name="name" placeholder="Insert Module Name" />
          </Form.Item>
          <SelectFormItem
            name="ownership_status"
            label={<FormItemLabel>Ownership Status</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={isAnzaActive ? [requiredRule] : notRequiredRules}
            selectProps={{
              placeholder: 'Ownership Status',
            }}
          >
            {moduleOwnershipOptions.map(({ value, label }) => (
              <Option value={value} key={value}>
                {label}
              </Option>
            ))}
          </SelectFormItem>
          {module?.uuid && (
            <Form.Item
              label={<FormItemLabel>Module UUID</FormItemLabel>}
              labelCol={{ span: 24 }}
            >
              <InputAnt.Group compact>
                <InputAnt
                  style={{
                    width: 'calc(100% - 64px)',
                    borderRadius: 0,
                  }}
                  defaultValue={module.uuid}
                  disabled
                />
                <Tooltip title="Copy UUID">
                  <Button
                    style={{ width: '32px', height: '32px', padding: 0 }}
                    icon={<IconCopy />}
                    onClick={() => {
                      navigator.clipboard.writeText(module.uuid).then(() => {
                        message.success('Module UUID copied to clipboard');
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Visit Module">
                  <Link
                    to={ROUTER_PATHS.modules.MODULE_DETAILS(module.uuid)}
                    style={{ borderRadius: 0 }}
                  >
                    <Button
                      icon={<IconView />}
                      style={{
                        borderRadius: 0,
                        width: '32px',
                        height: '32px',
                        padding: 0,
                      }}
                    />
                  </Link>
                </Tooltip>
              </InputAnt.Group>
            </Form.Item>
          )}
        </div>
        {companyId === firstSolarId && (
          <SelectFormItem
            name="series_name"
            label={<FormItemLabel>Series</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={notRequiredRules}
            selectProps={{ placeholder: 'Choose Series' }}
          >
            <Option value="Series 6">Series 6</Option>
            <Option value="Series 7">Series 7</Option>
          </SelectFormItem>
        )}
      </FormGroup>
      <FormGroup key="Basic" title="Basic" style={{ maxWidth: '100%' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(auto, 49%))',
            gridTemplateRows: 'auto',
            gap: '1rem',
          }}
        >
          <Form.Item
            name="watts"
            label={<FormItemLabel>Watt Class</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  100,
                  4000,
                  false,
                  'Please enter an integer between 100 and 4000, ex. 120',
                  isAnzaActive,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="watts"
              placeholder="Insert the wattage of the module"
              formatter={(val) => val as string}
              addonAfter="W"
            />
          </Form.Item>
          <Form.Item
            name="efficiency"
            label={<FormItemLabel>Module Efficiency</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  0.1,
                  0.35,
                  false,
                  'Please enter a number between 10% and 35%, ex. 20.20',
                  isAnzaActive
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              placeholder="Insert the efficiency of the module"
              precision={5}
              parser={PercentSettings.parser}
              step={0.01}
              formatter={PercentSettings.formatterWithFractionDigits(3)}
              stringMode
              addonAfter="%"
            />
          </Form.Item>
          <Form.Item
            name="is_bifacial"
            label={<FormItemLabel>Is Bifacial</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={isAnzaActive ? [requiredFieldRule!] : notRequiredRules}
            validateTrigger="onBlur"
          >
            <RadioGroup>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </RadioGroup>
          </Form.Item>
          <Form.Item
            name={MODULE_TAGS.TIER_1.value}
            label={<FormItemLabel>Tier-1</FormItemLabel>}
            labelCol={{ span: 24 }}
            validateTrigger="onBlur"
          >
            <RadioGroup>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </RadioGroup>
          </Form.Item>
          <SelectFormItem
            name="cell_technology"
            label={<FormItemLabel>Cell Technology</FormItemLabel>}
            labelCol={{ span: 24 }}
            selectProps={{
              placeholder: 'Select...',
              options: [
                MODULE_TAGS.PERC,
                MODULE_TAGS.TOP_CON,
                MODULE_TAGS.HJT,
                MODULE_TAGS.CDTE_THIN_FILM,
              ].map((tag) => {
                return {
                  value: tag.value,
                  label: tag.label,
                };
              }),
            }}
          />
        </div>
      </FormGroup>
    </>
  );
};

CompanyFormGroup.defaultProps = {
  companies: [],
};

export default memo(CompanyFormGroup);
