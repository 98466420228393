import React from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import * as R from 'ramda';

import { Col, PDFViewer, Row } from '~/UI';
import ResponsiveBarChart, {
  quarterYearTickFormatter,
} from '~/UI/Metrics/ResponsiveBarChart';
import { theme } from '~/utils';
import { getXAxisGroupingsFromDeliveryDateData } from '~/utils/dataProcessor';

interface ModuleHeroProps {
  vendor: string;
  moduleName: string;
  lastUpdated: string;
  data: Array<{ label: string; value: string | number }> | [];
  chartData: Array<{
    id: string;
    label: string;
    value: number;
    count: number;
  }>;
  thumbnailImageUrl?: string;
}

const Block = styled.div`
  background-color: ${theme.colors.white};
  height: 100%;
  max-height: 300px;
  padding: 1rem 30px;
`;

const StyledRow = styled(Row)`
  padding: 0.2rem 0;
`;

const VendorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ColumnBlock = styled(Block)`
  border-top-left-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PDFViewerContainer = styled.div`
  margin-right: 1rem;
`;

const ModuleHero: React.FC<ModuleHeroProps> = ({
  data,
  lastUpdated,
  moduleName,
  vendor,
  chartData,
  thumbnailImageUrl,
}) => {
  return (
    <>
      <StyledRow gutter={[2, 2]}>
        <Col span={12}>
          <ColumnBlock>
            <VendorContainer>
              <PDFViewerContainer>
                <PDFViewer fileUrl={thumbnailImageUrl || ''} height="140px" />
              </PDFViewerContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <>
                  <Typography.Text
                    style={{
                      fontWeight: 700,
                      fontSize: '52px',
                      lineHeight: '60px',
                    }}
                  >
                    {vendor}
                  </Typography.Text>
                  <Typography.Text
                    strong
                    style={{ fontSize: '32px', lineHeight: '40px' }}
                  >
                    {moduleName}
                  </Typography.Text>
                </>

                <Typography.Text
                  strong
                  style={{ fontSize: '20px', color: theme.colors.graphite }}
                >
                  Last updated at {lastUpdated}
                </Typography.Text>
              </div>
            </VendorContainer>
          </ColumnBlock>
        </Col>
        <Col span={12}>
          <Block style={{ borderTopRightRadius: '0.5rem' }}>
            <ResponsiveBarChart
              title="MW Available Per Quarter"
              data={chartData}
              keys={['count']}
              style={{ width: '100%' }}
              axisBottomTickFormat={quarterYearTickFormatter}
              customXAxis={getXAxisGroupingsFromDeliveryDateData(
                R.isNil(chartData) || R.isEmpty(chartData) ? [] : chartData
              )}
            />
          </Block>
        </Col>
        {data.map(({ label, value }, index) => (
          <Col span={6} key={label}>
            <Block
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderBottomLeftRadius: index === 0 ? '0.5rem' : 0,
                borderBottomRightRadius:
                  index === data.length - 1 ? '0.5rem' : 0,
              }}
            >
              <Typography.Text style={{ color: theme.colors.graphite }}>
                {label}
              </Typography.Text>
              <Typography.Text style={{ fontWeight: 700, fontSize: '1.2rem' }}>
                {value}
              </Typography.Text>
            </Block>
          </Col>
        ))}
      </StyledRow>
    </>
  );
};

export default ModuleHero;
