import * as R from 'ramda';
import React from 'react';

import { ButtonAnt as Button, Row } from '~/UI';
import { CompanyType, columnSorter, columnArrayStringSorter } from '~/utils';
import { getArrayAsTags } from '~/utils/cellRenderers';
import { Filter } from '~/components/Tables/SearchFilterDropdown';
import { IconAdd } from '~/UI/Icons';
import { H2 } from '~/UI/Typography';
import { AdminListPageProps } from '~/types/admin';
import { AccountManagerUserType } from '~/types/users';

import { ADMIN_ROUTES } from '../../../router/AdminRoutes';
import AdminTable, { Column } from '../AdminTable/AdminTable';
import { allCompanyTypes } from '../../../routes/AdminCompanies';

const optionMapper = {
  [CompanyType.VENDOR]: 'Vendor',
  [CompanyType.CUSTOMER]: 'Customer',
  [CompanyType.CORP]: 'Corporation',
  [CompanyType.RACK_VENDOR]: 'Rack Vendor',
  [CompanyType.INVERTER_COMPANY]: 'Inverter Vendor',
  [CompanyType.STORAGE_SUPPLIER]: 'Storage Supplier',
};

const AdminCompaniesList: React.FC<AdminListPageProps> = ({
  service,
  history,
  pageName,
}) => {
  const filterableTypes = allCompanyTypes
    .map((f: Filter) => ({
      value: f.value.toLowerCase(),
      text: f.text,
      isStrict: true,
    }))
    .filter((f: Filter) => {
      return [
        CompanyType.VENDOR,
        CompanyType.CORP,
        CompanyType.CUSTOMER,
        CompanyType.STORAGE_SUPPLIER,
      ].includes(f.value.toUpperCase());
    });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      isSortable: true,
      isFilterable: true,
      defaultSortOrder: 'ascend',
      sorter: columnSorter(['name']),
    },
    {
      title: 'Domains',
      dataIndex: 'domains',
      key: 'domains',
      isSortable: true,
      isFilterable: true,
      render: getArrayAsTags('domain'),
      sorter: columnArrayStringSorter(['domains'], ['domain']),
    },
    {
      title: 'Type',
      dataIndex: 'company_type',
      key: 'company_type',
      width: '150px',
      isSortable: true,
      isFilterable: true,
      filters: filterableTypes,
      render: (status: string) => R.propOr(status, status, optionMapper),
      sorter: columnSorter(['company_type']),
    },
    {
      title: 'Account Manager',
      dataIndex: 'accountManagerUser',
      key: 'accountManagerUser',
      render: (accountManagerUser: AccountManagerUserType) =>
        accountManagerUser?.email ?? '',
      isFilterable: true,
      isSortable: true,
      sorter: columnSorter(['accountManagerUser', 'email']),
    },
  ];
  return (
    <div>
      <Row justify="space-between">
        <H2>{pageName}</H2>
        <Button onClick={() => history.push(ADMIN_ROUTES.COMPANIES.path.new)}>
          <IconAdd style={{ marginRight: '0.25rem' }} /> New Company
        </Button>
      </Row>
      <AdminTable
        service={service}
        columns={columns as Column[]}
        customActions={{
          edit: {
            onHref: (company) =>
              ADMIN_ROUTES.COMPANIES.path.edit(company.id?.toString() || ''),
          },
          delete: {
            visible: true,
          },
        }}
      />
    </div>
  );
};

export default AdminCompaniesList;
