import { Location } from 'history';

import { omit, pick } from 'ramda';
import { BaseProject, ProjectDetails } from '~/store/project';
import type { AdminAnzaProjectItem, AnzaProject } from '~/types/projects';
import { ROUTER_PATHS } from '~/router/Paths';
import { UserType } from '~/types/users';
import { ProjectTypes } from './anzaProjectTypes';
import { DEFAULT_ADMIN_ROUTE } from '~/router/routes';

const { SOLAR, STORAGE } = ProjectTypes;

const projectDetailsKeys: (keyof ProjectDetails)[] = [
  'uuid',
  'racking_type',
  'arrival_date',
  'size_in_kw',
  'project_address',
  'project_type',
  'anticipated_purchase_date',
  'latitude',
  'longitude',
  'state',
  'location_id',
  'google_place_id',
  'status',
  'current_purchase_volume_kw',
  'storage_arrival_date',
  'is_storage_approved',
  'user_id',
  'metering_location',
  'target_ac_power_mw',
  'duration_hours',
  'power_factor',
  'annual_cycle_count',
  'project_topology',
  'capacity_maintenance_term',
  'storage_notes',
  'depth_of_discharge',
  'resting_state_of_charge',
  'includes_augmentation',
  'pcs_warranty_purchase',
  'pcs_maintenance_purchase',
  'dc_block_warranty_purchase',
  'dc_block_maintenance_purchase',
  'capacity_maintenance_strategy',
  'is_test',
  'availability_override',
  'tax_credits',
  'is_itc',
];

export const omitProjectDetailsValues = omit(projectDetailsKeys);
export const pickProjectDetailsValues = pick(projectDetailsKeys);

export const getArrivalDateBasedOnProjectDetailsType = (
  projectDetails: ProjectDetails | AnzaProject | AdminAnzaProjectItem
): string | undefined => {
  return (
    projectDetails?.storage_arrival_date?.toString() ||
    projectDetails?.arrival_date.toString()
  );
};

export interface MDPLocation extends Location {
  state: {
    fromProject: string;
  };
}

export const navigateBackToPDP = (
  location: MDPLocation,
  defaultRoute: string
) => {
  const fromProject = !!location?.state?.fromProject;
  let to = '';
  const firstPageRender = !window.history.state;
  const params = { fromLocation: '' };
  if (firstPageRender) {
    to = defaultRoute;
  } else if (fromProject) {
    to = DEFAULT_ADMIN_ROUTE;
    params.fromLocation = 'Module';
  }

  return { to, params };
};

export const isUserEngagedInProject = (
  user: UserType,
  project: BaseProject
) => {
  if (!user.company) return false;
  const { engaged_solar, engaged_storage } = user.company;
  const { project_type } = project.project_details;
  const isEngagedSolar = engaged_solar && project_type === SOLAR;
  const isEngagedStorage = engaged_storage && project_type === STORAGE;
  return isEngagedSolar || isEngagedStorage;
};
